<!-- <app-header></app-header> -->
<router-outlet></router-outlet>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<div id="attractions" class="container my-5">
    <div class="row">
        <!-- Left Side -->
        <div class="col-md-5 col-12 mb-4 d-flex align-items-center justify-content-center flex-column text-center">
            <h1 class="explore-heading">EXPLORE</h1>
            <h1 class="explore-subheading">THE CITY</h1>
            <p class="description">
                Our knowledgeable concierge team can assist you in planning your itinerary and arranging tours, tickets, and transportation.
            </p>
            <a (click)="scrollToSection('location')" class="contact-button btn btn-primary">Contact Us</a>
        </div>

        <!-- Right Side (Grid of Images) -->
        <!-- {{this.token?.getProperty()?.address?.city}} -->
        <div class="col-md-7 col-12">
          <main *ngIf="blogPosts$ | async as blogPosts">
            <div *ngFor="let blogPost of blogPosts.items; let i = index">
            <div class="row" >
              <ng-container *ngFor="let item of blogPost.fields.citiesimages; let j = index">
              <div  class="col-md-6 col-12 mb-4" *ngIf="j < 4 && blogPost.fields.title === this.tokenStorage?.getProperty()?.address?.city">

                  <div *ngIf="blogPost.fields.title === this.tokenStorage?.getProperty()?.address?.city ">

                      <div class="card" style="z-index: 1; margin-bottom: -40px; border-radius: 15px;">
                        <img [src]="item.fields.file.url" class="card-img-top" style="border-radius: 15px;height:200px" alt="This Temple">
                      </div>
                      <div class="card-body text-center card-body-style" style="background-color: var(--primary) !important; margin-left: -10px; margin-right: -10px; border-radius: 10px; padding-top: 50px;">
                        <h5 class="card-title">{{ item.fields.title }}</h5>
                      </div>

                  </div>

              </div>
              </ng-container >
            </div>

          </div>
          </main>
        </div>


            </div>
</div>



<div class="container my-5">
  <div class="row" id="testimonials">
    <!-- First Column -->
    <div class="col-md-4 mb-4 d-flex flex-column justify-content-center align-items-start">
      <h2 class="headline font-weight-bold mb-3">Real Stays, Real Talk</h2>
      <p class="intro-text mb-4 text-muted">Here's what our guests are saying...</p>
      <a href="https://www.google.com/travel/search?q=fab%20hotel%20sai%20govind%20shirdi&g2lb=4814050%2C4874190%2C4893075%2C4965990%2C4969803%2C72277293%2C72302247%2C72317059%2C72406588%2C72414906%2C72421566%2C72470899%2C72471280%2C72472051%2C72473841%2C72481459%2C72485658%2C72486593%2C72520081%2C72536387%2C72569093%2C72602734%2C72614662%2C72616120%2C72619927%2C72620306%2C72626061%2C72628719%2C72635576%2C72640790%2C72647020%2C72648289%2C72658035%2C72663438%2C72671093%2C72686036%2C72686823%2C72691805%2C72697776%2C72710128%2C72730315&hl=en-IN&gl=in&cs=1&ssta=1&ts=CAEaRwopEicyJTB4M2JkYzViMzUyOWMyNDU4MzoweDI4ODVjZTM0ZjViYTc3OGMSGhIUCgcI6A8QCRgGEgcI6A8QCRgHGAEyAhAA&qs=CAEyE0Nnb0lqT19wcmNfRzg4SW9FQUU4AkIJCYx3uvU0zoUoQgkJjHe69TTOhSg&ap=ugEHcmV2aWV3cw&ictx=111&ved=0CAAQ5JsGahcKEwj4gaqu16yIAxUAAAAAHQAAAAAQCw&cshid=1725569504728186" class="btn btn-primary btn-lg writereview">
      <div style="padding-left: 10px;padding-right: 10px;">
        Write a Review
      </div>
    </a>
    </div>

    <!-- Second Column (Carousel for Desktop) -->
    <div class="col-md-8 mb-4  d-md-block">
      <div class="card shadow-sm border-0 h-100">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner style_carasol"  >
            <div
              class="carousel-item" data-interval="10000"
              *ngFor="let item of googleReviews; let i = index"
              [ngClass]="{ 'active': i === 0 }"
            >
              <div class="row">
                <div class="col-md-6 d-flex align-items-center">
                  <div class="testimonial p-3">


                       <div style="text-align: center;">
                        <div>
                          <img
                          src="assets/images/test.png"
                          alt="{{ item.reviewerName }}"
                          class="rounded-circle img-fluid shadow-lg mb-3"
                          style="width: 80px; height: 80px;"
                        />
                        <br/>
                        </div>

                       <div>
                        <h5 class="font-weight-bold mb-1">{{ item.reviewerName }}</h5>
                       </div>
                      <div>
                        <p class="small text-muted mb-2 review-text">{{ item.reviewText }}</p>
                      </div>

                        <div class="star-ratings text-warning">
                          {{ '★'.repeat(item.rating) }}
                        </div>
                       </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Carousel Controls Positioned Outside -->
      <div class="carousel-controls">
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>




  </div>
</div>






  <div id="location" style="margin-bottom: 2px;">
  <div style="position: relative; width: 100%; max-width: 100%; height: 600px;">
    <!-- The iframe -->
    <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d120149.28869375587!2d74.40314829893256!3d19.769371139353584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bdc5b3529c24583%3A0x2885ce34f5ba778c!2sSilver%20Oak%20Platinum%2C%20Pimpalwadi%20Rd%2C%20opp.%20Hp%20Gas%20Agency%2C%20Shirdi%2C%20Maharashtra%20423109!3m2!1d19.769389999999998!2d74.48555!5e0!3m2!1sen!2sin!4v1725570363296!5m2!1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    <div   class="card" style="position: absolute; top: 50px; text-align: center; left: 50px; background-color: white; border-bottom-left-radius: 61px;box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-top-right-radius: 59px;
    padding: 50px;">
        <h3>Make Unforgettable Memories</h3>
        <p>We're here to assist you with any questions, requests, or bookings.<br> Please don't hesitate to reach out to us.</p>
        <button class="book-btn" (click)="navigate()">Book Your Stay</button>
        <p class="contact-footer">
          <i class="fa fa-phone"></i> {{property?.mobile}}
        </p>
        <p class="contact-footer">
          <i class="fa fa-envelope"></i> {{property?.email}}
        </p>

      </div>

  </div>
</div>


<app-footer></app-footer>

<a [href]="getWhatsappShareUrl()" class="float" target="_blank" *ngIf="showHideFlag === false">
  <i class="fa fa-whatsapp my-float"></i>
  </a>
