import { Component, OnInit, AfterViewInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BlogPostService } from './blog-post.service';
import { Observable } from 'rxjs';
import { TokenStorage } from './token.storage';
import { ApiService } from './api.service';
import { Router } from '@angular/router';

declare var $: any; // Declare jQuery to avoid TypeScript errors

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'hotelWebsite';
  blogPosts$: Observable<any> | undefined;
  property: any;
  businessUser: any;
  googleReviews: any;
  chunkedReviews: any = [];
  showHideFlag: boolean = false;
  dynamicText: string | undefined;
  dynamicPropertyId: string | undefined;
  dynamicLocality: string | undefined;
  dynamicCity: string | undefined;
  dynamicStreetName: string | undefined;
  dynamicCountryName: string | undefined;
  dynamicStreetNumber: string | undefined;
  viewportScroller: any;

  constructor(
    public router: Router,
    private contentfulService: BlogPostService,
    private tokenStorage: TokenStorage,
    private apiService: ApiService,
    private renderer: Renderer2,
    private cdrf:ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.blogPosts$ = this.contentfulService.getAllEntries();
    this.businessUser = this.tokenStorage.getProperty();

  if (this.businessUser != null && this.businessUser != undefined) {
    this.getProperty();

  }
    this.getGoogleReview();

    if (this.businessUser) {
      this.changeTheme(
        this.businessUser.primaryColor,
        this.businessUser.secondaryColor,
        this.businessUser.tertiaryColor
      );
    }
  }

  getWhatsappShareUrl(): string {
    const baseUrl = 'https://api.whatsapp.com/send';
    const phoneNumber = this.businessUser?.whatsApp; // Assuming `whatsApp` is part of `businessUser`

    // Assign dynamic properties
    this.dynamicText = this.businessUser?.name;
    this.dynamicPropertyId = this.businessUser?.id;
    this.dynamicCity = this.businessUser?.address?.city;
    this.dynamicStreetName = this.businessUser?.address?.streetName;
    this.dynamicStreetNumber = this.businessUser?.address?.streetNumber;
    this.dynamicLocality = this.businessUser?.address?.locality;
    this.dynamicCountryName = this.businessUser?.address?.country;

    // Construct the message for WhatsApp
    const message = `*This is an Enquiry from :*Hotel Name: ${this.dynamicText || ''}`;

    // Encode the URL to ensure it's properly formatted for WhatsApp
    return `${baseUrl}?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
  }

  chunkReviews() {
    const chunkSize = 2;
    for (let i = 0; i < this.googleReviews?.length; i += chunkSize) {
      this.chunkedReviews.push(this.googleReviews.slice(i, i + chunkSize));
    }
  }

  getProperty() {
    this.apiService.getPropertyDetailsByPropertyId(PROPERTY_ID).subscribe(
      (response) => {
        this.property = response.body;
        this.businessUser = response.body;
        this.tokenStorage.saveProperty(this.property);
        this.initializeSlideItems();
      },
      (error: HttpErrorResponse) => {
        console.error('Error fetching property details:', error.message);
      }
    );
  }

  ngAfterViewInit() {
    this.getProperty();

    // Initialize Owl Carousel after view initialization
    $(document).ready(() => {
      $('.owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
          0: { items: 1 },
          768: { items: 2 },
          992: { items: 2 }
        }
      });
    });

    // Add click event listeners to "Read More" links
    const readMoreLinks = document.querySelectorAll('.read-more');
    readMoreLinks.forEach((link) => {
      this.renderer.listen(link, 'click', (event) => this.expandText(event));
    });
  }

  getGoogleReview() {
    this.apiService.getGoogleReview(this.tokenStorage.getProperty().id).subscribe(
      (response) => {
        this.googleReviews = response.body;
        this.chunkReviews();
      }
    );
  }

  changeTheme(primary: string, secondary: string, tertiary: string) {
    document.documentElement.style.setProperty('--primary', primary);
    document.documentElement.style.setProperty('--secondary', secondary);
    document.documentElement.style.setProperty('--tertiary', tertiary);
    document.documentElement.style.setProperty('--button-primary', tertiary);
    document.documentElement.style.setProperty(
      '--primary-gradient',
      `linear-gradient(180deg, ${tertiary}, ${secondary})`
    );
    document.documentElement.style.setProperty(
      '--secondary-gradient',
      `linear-gradient(312deg, ${primary}, ${secondary})`
    );
    document.documentElement.style.setProperty(
      '--secondary-one-gradient',
      `linear-gradient(180deg, ${primary}, ${secondary})`
    );
    document.documentElement.style.setProperty(
      '--third-gradient',
      `linear-gradient(180deg, ${primary}, ${secondary})`
    );
  }

  expandText(event: Event) {
    const target = event.target as HTMLElement;
    const parent = target.closest('.testimonial');
    if (!parent) return;

    const textContainer = parent.querySelector('.text-truncated');
    if (!textContainer) return;

    const fullText = textContainer.getAttribute('data-full-text');
    if (fullText) {
      textContainer.textContent = fullText;
      target.style.display = 'none';
    }
  }

  navigate() {
    const seoFriendlyName = this.tokenStorage.getProperty()?.seoFriendlyName || '';
    window.open(`https://bookonepms.com/${seoFriendlyName}?BookingEngine=true`, '_blank');
  }

  initializeSlideItems() {
    // Add implementation for initializing slide items
  }

  scrollToSection(section: string): void {
    this.router.navigate([], { fragment: section }).then(() => {
      this.viewportScroller.scrollToAnchor(section);
    });
  }
}

export const APP_ID = environment.appId;
export const PROPERTY_ID = environment.propertyId;
